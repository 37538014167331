/**
 * External dependencies
 */
import $ from 'jquery';

// eslint-disable-next-line no-unused-vars
(function(document, jQuery, undefined) {
	function initMap() {
		// ..get the ellement and trigger click on map message click

		const marker = l18n_js_location_map_vars.markers[0];

		const styles = [
			{
				featureType: 'all',
				elementType: 'labels.text.fill',
				stylers: [{ saturation: -100 }],
			},
			{
				featureType: 'all',
				elementType: 'labels.text.stroke',
				stylers: [{ visibility: 'on' }, { saturation: -100 }],
			},
			{
				featureType: 'all',
				elementType: 'labels.icon',
				stylers: [{ visibility: 'on' }, { saturation: -100 }],
			},
			{
				featureType: 'administrative',
				elementType: 'geometry.fill',
				stylers: [{ saturation: -100 }],
			},
			{
				featureType: 'administrative',
				elementType: 'geometry.stroke',
				stylers: [{ saturation: -100 }, { weight: 1.2 }],
			},
			{
				featureType: 'landscape',
				elementType: 'geometry',
				stylers: [{ saturation: -100 }],
			},
			{
				featureType: 'poi',
				elementType: 'geometry',
				stylers: [{ saturation: -100 }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.fill',
				stylers: [{ saturation: -100 }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.stroke',
				stylers: [{ saturation: -100 }],
			},
			{
				featureType: 'road.arterial',
				elementType: 'geometry',
				stylers: [{ saturation: -100 }],
			},
			{
				featureType: 'road.local',
				elementType: 'geometry',
				stylers: [{ saturation: -100 }],
			},
			{
				featureType: 'transit',
				elementType: 'geometry',
				stylers: [{ saturation: -100 }],
			},
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [{ saturation: -100 }],
			},
		];

		const icon = {
			// eslint-disable-next-line no-undef
			url: `${l18n_js_location_map_vars.icon}`,
			// eslint-disable-next-line no-undef
			size: new google.maps.Size(40, 50), // scaled size
			// eslint-disable-next-line no-undef
			scaledSize: new google.maps.Size(40, 50), // scaled size
			// eslint-disable-next-line no-undef
			labelOrigin: new google.maps.Point(20, 18),
		};
		const myLatLng = { lat: +marker.company_lat, lng: +marker.company_lng };
		const map = new google.maps.Map(
			document.getElementById('single-location-map'),
			{
				zoom: 15,
				center: myLatLng,
				noWrap: true,
				scrollwheel: false,
				// eslint-disable-next-line no-undef
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				mapTypeControl: false,
				streetViewControl: false,
				zoomControlOptions: {
					// eslint-disable-next-line no-undef
					position: google.maps.ControlPosition.RIGHT_TOP,
				},
				styles,
			}
		);

		let logo = '';
		if (marker.company_logo !== null) {
			const imageExtension = marker.company_logo.split('.').pop(),
				imageUrl = marker.company_logo.split(`.${imageExtension}`),
				retinaImage = `${imageUrl[0]}@2x.${imageExtension}`;
			logo = `<div class="marker-item-visual"><img alt="${marker.company_name}" srcset="${marker.company_logo}, ${retinaImage} 2x" loading="lazy"></div>`;
		} else {
			// logo = `<img src="${marker.company_logo}" alt="${marker.company_name}" />`;
			logo = '';
		}
		// const pdf = marker.company_pdf
		// 	? `<li><a href="${marker.company_pdf}" target="_blank">${marker.company_pdf_txt}</a></li>`
		// 	: '';
		const companyName = marker.company_name
			? `<span>${marker.company_name}</span>`
			: '';
		const companyAddress = marker.company_address
			? marker.company_address
			: '';
		const companyZip = marker.company_zip ? marker.company_zip : '';
		const companyCity = marker.company_city ? marker.company_city : '';
		const companyPhone = marker.company_phone
			? `<li>Tel. <a href="tel:${marker.company_phone.replace(
					/\s/g,
					''
			  )}" itemprop="telephone">${marker.company_phone}</a></li>`
			: '';
		const companyMail = marker.company_email
			? `<li><a href="mailto:${marker.company_email}" title="Click to e-mail us" itemprop="email">${marker.company_email}</a></li>`
			: '';
		const pdf = marker.company_person_url
			? `<li><a href="${marker.company_person_url}" target="_blank">${marker.company_person_url_txt}</a></li>`
			: '';
		const locationWebsite = marker.company_site
			? `<li><a href="${marker.company_site}">${
					marker.company_site
						.replace(/^(?:https?:\/\/)/i, '')
						.split('/')[0]
			  }</a></li>`
			: '';
		// const drivingDirLnk = `<!--<li><a href="https://www.google.com/maps/dir/?api=1&destination=${marker.company_address},${marker.company_city},CH&travelmode=driving" title="Route erstellen" target="_blank">Route erstellen</a></li>-->`;
		const drivingDirLnk = marker?.company_gmap_url
			? `<li><a href="${marker?.company_gmap_url}" title="Anfahrt" target="_blank">Anfahrt</a></li>`
			: '';
		const infoWindowContent = `<div class="marker-item">${logo}

<div class="marker-item-details">
${companyName}
<ul>
<li>${companyAddress}, ${companyZip}, ${companyCity} </li>
${companyPhone}${companyMail}${locationWebsite}${pdf}${drivingDirLnk}</ul></div></div>`;

		const infowindow = new google.maps.InfoWindow({
			content: infoWindowContent,
		});

		const markerItem = new google.maps.Marker({
			position: myLatLng,
			map,
			icon,
			title: marker.company_name,
			animation: google.maps.Animation.DROP,
		});

		// console.log('Marker item', markerItem);
		markerItem.addListener('click', () => {
			infowindow.open({
				anchor: markerItem,
				map,
				shouldFocus: false,
			});
		});
		// infowindow.set('closed', true);
		google.maps.event.addListener(map, 'click', function() {
			infowindow.close();
		});
	}

	initMap();
})(document, $);
